import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../../common/service/services/common.service';
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { ShowErrorService } from '../../../../common/service/services/show-error.service';
import { RestrictKeyPressService } from '../../../../common/service/services/restrict-key-press.service';
import { Router } from '@angular/router';
// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';
import { BroadcasterService } from 'src/app/common/service/services/broadcaster.service';
import { __values } from 'tslib';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';


@Component({
  selector: 'app-assign-modal',
  templateUrl: './assign-modal.component.html',
  styles: [
  ]
})
export class AssignModalComponent implements OnInit {
  public callAPIConstants = callAPIConstants;
  title;
  searchText;
  commonObject:any = {};
  public dataResp;
  public city;
  public submitted = false;
  public data;
  public type;
  public apiname;
  public driverList :any=[];
  public orderList;
public driver:any={};
public  allDrviersList: any =[];
  constructor(public modalService:BsModalService,
    public router: Router,
    public modalRef : BsModalRef, 
    public commonService : CommonService,
    public showErrorService: ShowErrorService, 
    public broadCasterService: BroadcasterService,
     public restrictKeyPressService: RestrictKeyPressService) {  
  }
  ngOnInit(): void {
    if(this.data){    
      this.commonObject = this.data;
      this.checkDriver("");
     this.city = this.commonObject.shippingAddress.city;
     }     
  }
   //getcountries List API 
  getDriverDetails(form,commonObject){
    let obj = {searchText: ''}
    this.commonService.callApi(this.callAPIConstants.DriverList, obj, 'post', false, false, false).then((success) => {
      if (success.status === 1) {    
        this.driverList = success.data;
      }
    }); 
 }
  
    filterOrders(event){
       let obj = event ?  event.target.value : '';
       const filter_data = { filter: { orderId: obj}, orderIds: this.data.orderIds }; 
       this.commonService.callApi(this.callAPIConstants.Agent_getOrderDetails, filter_data, 'post', false, false, false).then((success) => {
        if (success.status === 1) {
          this.driverList = success.data;  
          this.commonObject.orderIds = '';  
        }
      }); 
    }
    //add update
    searchdriverInfo(form,commonObject){
      this.submitted = true; 
      let obj;
      if (form.valid) {
        this.apiname = callAPIConstants.Agent_assignDriver;
       const apiData =this.commonObject.currencyId = this.data._id;
        this.commonService.callApi(this.apiname, apiData, 'post', false, false).then((res) => {
          if(res.status == 1){
            this.showErrorService.popToast('success', res.message);
            this.modalRef.hide();
            window.location.reload()
          }
          else{
            this.showErrorService.popToast('error', res.message);
          }
        })
      }  else{
        this.submitted = true;
      }
  
      
    }

/*************************************************************
  @Purpose    : Get driver list
  @Parameter  : form, client
  @Return     : NA
  /*************************************************************/
  
  driversList: Array<any> = [];
  checkDriver(search) {
    const obj = {
      'searchText': search,
      'longitude':this.commonObject.shippingAddress.geoLocation.coordinates[0],
      'latitude':this.commonObject.shippingAddress.geoLocation.coordinates[1]
    };
    this.commonService.callApi(this.callAPIConstants.Agent_getDriversList,obj,'post', false, false, false)
      .then((success) => { 
        if (success.status === 1) 
        { 
          this.allDrviersList = success.data; 
        } 
      });
  }
  assignDriver(driverId){
    let apiData:any={};
    apiData.orderId=this.commonObject._id;
    apiData.driverId=driverId;
    this.commonService.callApi(this.callAPIConstants.Agent_assignDriver, apiData, 'post', false, false).then((res) => {
      if(res.status == 1){
        this.showErrorService.popToast('success', res.message);
        this.modalRef.hide();
        // window.location.reload()
        this.modalService.onHidden.emit({ data: true })

      }
      else{
        this.showErrorService.popToast('error', res.message);
      }
    })
  }
}
