<div class="authentic-page">
  <div class="authentic-wrapper">
    <div class="row">
      <div class="col-md-6 p-0 login-logo">
        <img src="assets/images/logo.png" class="header-logo" alt="IndiaNic" title="IndiaNic" />
      </div>
      <div class="col-md-6 p-0">
        <div class="login-container">
          <div class="login-section">
            <h3 class="pb-2">{{passwordData.headerTitle}}</h3>
            <form class="example-form" #passwordForm="ngForm" (ngSubmit)="onSubmitPassword(passwordForm, password)"
              content-type="application/x-www-form-urlencoded">
              <div class='form-group row'>
                <label htmlFor="New Password">New Password:</label>
                <div class="col-md-12">
                  <input class="form-control" placeholder="New Password" type="password" name="password"
                    autocomplete="on" [(ngModel)]="password.password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" value=""
                    id="password" required #newPassword="ngModel" />
                  <div class="error-block text-left"
                    *ngIf="((submitted || newPassword.touched) && newPassword.invalid)">
                    {{showErrorService.showError('newPassword', newPassword.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <label htmlFor="Confirm Password">Confirm Password:</label>
                <div class="col-md-12">
                  <input class="form-control" placeholder="Confirm Password" type="password" name="confirmpassword"
                    [(ngModel)]="password.confirmpassword"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"  value=""
                    autocomplete="off" id="confirmpassword" required #confirmPassword="ngModel" />
                  <div class="error-block text-left"
                    *ngIf="((submitted || confirmPassword.touched) && confirmPassword.invalid)">
                    {{showErrorService.showError('confirmPassword', confirmPassword.errors)}}
                  </div>
                </div>
              </div>
              <div class='mt-5'>
                <button class='btn btn-block btn-primary' type='submit'> {{passwordData.buttonTitle}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>