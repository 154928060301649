
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
//Service
import { CommonService } from '../../common/service/services/common.service';
import { ShowErrorService } from '../../common/service/services/show-error.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [],
})

export class ResetPasswordComponent implements OnInit {

  callAPIConstants = callAPIConstants
  URLConstants = URLConstants;
  public token;

  constructor(public commonService: CommonService,public showErrorService: ShowErrorService,public router: Router ) {
    this.token = this.router.parseUrl(this.router.url).queryParams['token'];
  }

  ngOnInit() {
  }

  /*************************************************************
	@Purpose     : reset password
	@Parameter   : NA
	@Return      : url
	/*************************************************************/
  public password: any = {};
  public submitted = false;
  onSubmitPassword(passwordForm, user) {
    this.submitted = true;
    if (passwordForm.valid) {
      if (user.password !== user.confirmpassword) {
        this.showErrorService.popToast('error', 'New Password and confirm password must be same.');
        passwordForm.reset();
        return false;
      }
      const fromObject = {token: this.token,password: user.password};
      this.commonService.callApi(this.callAPIConstants.Agent_resetPassword, fromObject, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/
}
