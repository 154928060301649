import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent { 

	ngOnInit() { 

		var header = $(".start-style");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
	        if (scroll >= 200) {
	            header.removeClass('start-style').addClass("scroll-on");
	        } else {
	            header.removeClass("scroll-on").addClass('start-style');
	        }

	    	var $scrollTop = $(window).scrollTop();
		    if ($scrollTop > 60) {
		      $("body").addClass("navbar-scrolled");
		    }else{
		      $("body").removeClass("navbar-scrolled");
		    }
		    if ($scrollTop > 20) {
		      $("body").addClass("page-scrolled");
		    }
		    else{
		      $("body").removeClass("page-scrolled");
		    }
	  	});
	}

}
