import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner'

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/services/common.service';
import { ShowErrorService } from '../../common/service/services/show-error.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styles: [
  ]
})
export class EmailVerifyComponent implements OnInit {

  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  data: any;
  constructor(private activatedRoute: ActivatedRoute, 
    private commonService: CommonService, 
    private showErrorService: ShowErrorService,
    public spinnerService: NgxSpinnerService,
    private router: Router) {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      let token = queryParams['token'];
      let method = 'agent/verifyUser'
      this.verifyEmail(token);
      });
  }

   ngOnInit(): void { 
  }

  /*************************************************************
  @Purpose     : call api to verify email
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  
  verifyEmail(token) {
    this.spinnerService.show()
    const user = { token : token};
    this.commonService.callApi(`${this.callAPIConstants.Agent_verifyUser}?token=${token}`,'user', 'get', true, false).then((success) => {
      this.spinnerService.hide();
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        // this.router.navigate([this.URLConstants.DASHBOARD]);
      } else {
        this.showErrorService.popToast('error', success.message);
      }
    });
}

}
