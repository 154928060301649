import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

// Shared
import { SharedModule } from './shared/shared/shared.module';

// Auth Gaurd
import {
  CanAuthActivate,
  CanLoginActivate,
  CanViewActivate,
} from './common/service/TS-files/auth.gaurd';

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from './public/login/login.component';
import { LoginAgentComponent } from './public/login-agent/login-agent.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { SetPasswordComponent } from './public/set-password/set-password.component';
import{ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { RegisterComponent } from './public/register/register.component';
import { EmailVerifyComponent } from './public/email-verify/email-verify.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [CanLoginActivate],
    component: LoginAgentComponent,
    pathMatch: 'full',
  },
   {
     path: 'update-info',
     component: RegisterComponent,
     pathMatch: 'full',
   },
  {
    path: 'forgot-password',
     canActivate: [CanLoginActivate],
     component: ForgotPasswordComponent,
     pathMatch: 'full',
  },
  {
    path: 'reset-password',
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'verify',
    component: EmailVerifyComponent,
    pathMatch: 'full',
  },
  {
    path: 'set-password',
    canActivate: [CanLoginActivate],
    component: SetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule) },
      {
        path: 'drivers',
        canActivate: [CanViewActivate],
        data: { role: 'driver' },
        loadChildren: () => import('./modules/user-management/drivers/drivers.module').then((m) => m.DriversModule),
      },
     
      
      {
        path: 'change-password',
        loadChildren: () => import('./modules/change-password/change-password.module')
          .then((m) => m.ChangePasswordModule),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module')
          .then((m) => m.MyProfileModule),
      },
      {
        path: 'orders',
        canActivate: [CanViewActivate],
        data: { role: 'orders' },
        loadChildren: () => import('./modules/sales/orders/orders.module')
          .then((m) => m.OrdersModule),
      },
      { path: 'earning-reports', loadChildren: () => import('./modules/earning-reports/earning-reports.module').then(m => m.ReportsModule) },
      { path: 'profiles', loadChildren: () => import('./modules/profiles/profiles.module').then(m => m.ProfilesModule) },      
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  
  { path: 'forgot-password', loadChildren: () => import('./public/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'login-agent', loadChildren: () => import('./public/login-agent/login-agent.module').then(m => m.LoginAgentModule) },
  
   
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    MainComponent,
    EmailVerifyComponent
  ],
  imports: [SharedModule, CommonModule, FormsModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    CanAuthActivate,
    CanLoginActivate,
    CanViewActivate,
  ],
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
];
