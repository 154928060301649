export const callAPIConstants = {
    AdminLogin: 'adminLogin',
    AdminForgotPassword: 'forgotPasswordForAdmin',
    AdminResetPassword: 'resetPasswordForAdmin',
    AdminSetPassword: 'admin/setPassword',
    AdminGetProfile: 'getAdminProfile',
    AdminFileUpload: 'fileUpload',
    AdminEditProfile: 'editAdminProfile',
    AdminChangePassword: 'changePasswordForAdmin',
    AdminLogout: 'logOut',

    // Common
    ColumnSettings: 'agent/saveColumnSettings',
    GetFilter: 'agent/getFilters/',
    SaveFilter: 'agent/saveFilter',
    DeleteFilter: 'agent/deleteFilter/',
    CategoryList: 'categoryListing',

    GetParentCategoryName: 'getParentCategoryName/',
    CategoryChangeStatus: 'changeCategoryStatus',
    CategoryColumnData: 'categoryList',
    CategoryDelete: 'deleteCategories',
    AddCategory: 'addCategory',
    getCategoryDetails: 'getCategoriesDetails/',
    CategoryFileUpload: 'fileUploadForCategoryImage',
    CategoryDownloadforDataTable: 'downloadCategoriesFile',

    AddSubCategory: 'addSubCategory',

    ProductList: 'productListing',
    ProductChangeStatus: 'changeProductStatus',
    ProductDelete: 'deleteProducts',
    ProductDownloadforDataTable: 'downloadProductsFile',
    GetProductDetail: 'getProductDetails/',
    AddProducts: 'addProducts',
    GetCategories: 'catLevels',
    ProductFieldsList: 'productFieldsList',
    ProductFileUpload: 'fileUploadForProduct',
    MultipleImages: 'uploadMultipleImages',
    DeleteProductImage: 'deleteProductImage/',
    DeleteAdditionalImages: 'deleteAdditionalImages',
    RelatedProductList: 'relatedProductsListing',
    RelatedProductDelete: 'deleteRelatedProduct',
    GetAllCategories: 'getAllCategories',
    productList: 'productListForRelatedProducts',
    GetProductReviewDetails: 'getAllReviewsOfProduct/',

    addVendorProducts : 'addVendorProducts',
    deleteVendorProducts : 'deleteVendorProducts',
    getVendorProductDetails : 'getVendorProductDetails/',
    vendorProductListing : 'vendorProductListing',
    vendorProductFieldsList : 'vendorProductFieldsList',
    downloadvendorProductsFile : 'downloadvendorProductsFile',

    RoleList: 'listRole',
    GetRoleDetail: 'getRolePermission/',
    AddUpdateRole: 'addRole',
    RoleChangeStatus: 'changeRoleStatus',
    RoleGetColumnData: 'roleFieldsList',
    RoleGetaddedByColumnData: 'adminListInRoles',

    GetDashboardTotalData: 'getTotaldashboardData',
    GetBestSellers: 'getTopselling',
    MostViewUsers: 'getMostviewedProducts',
    GetNewUsers: 'getNewUsers',
    getOrders: 'getOrdersAllStatus',
    DashboardCharts: 'getStatistics',


    home: 'homePageDetails',
    AddUpdatehome: 'addHomePageDetails',

    CmsList: 'cmsListing',
    CmsDelete: 'deleteCms',
    CmsFilter: 'cmsFieldsList',
    CmsDetail: 'getcmsDetails/',
    CmsAddUpdate: 'addCms',
    CMSDownloadforDataTable: 'downloadCmsFile',
    CMSChangeStatus: 'changeCmsStatus',
    GetCMSDetails: 'getcmsDetails/',

    GlobalSettignsGlobalSettings: 'getDefaultMetaData',
    GlobalSettingsUpdate: 'addDefaultMetaData',
    FileUploadForDefaultImages: 'fileUploadForDefaultImages',
    GetNotificationSettings: 'getNotificationSettings',
    setNotificationSettings: 'setNotificationSettings',
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',

    GetSMTPsettings: 'getSMTPAndSMSSettings',
    AddSMTPsettings: 'addSMTPAndSMSSettings',

    uploadBulkMultipleImages: 'uploadBulkMultipleImages',
    DownloadCsv: 'downloadSampleCsv',
    BulkUploadProduct: 'bulkUploadProduct',
    UploadZipFile: 'uploadZipFile',

    UserList: 'userListing',
    UserDeleteUsers: 'deleteUsers',
    UserChangeStatus: 'changeUserStatus',
    GetUserDetails: 'gettingUserDetails/',
    UserOrderList: 'userOrderListing',
    UserWishListListing: 'userWishListListing',
    downloadUserFile: 'downloadUserFile',
    UserGetColumnData: 'userFieldsList',

    AdminUserlist: 'adminListing',
    AdminGetColumnData: 'adminFieldsList',
    AdminDeleteUsers: 'deleteAdmins',
    AdminChangeStatus: 'changeAdminStatus',
    downloadAdminFile: 'downloadAdminFile',
    AdminGetUserDetails: 'getSubAdminProfile/',
    AdminAddAdminUser: 'admin/addAdminUser',
    AdminUpdateUser: 'admin/updateUser',
    AddUpdateAdminUser: 'addAdmin',

    VendorGetColumnData: 'adminRolesFieldsList',
    VendorViewDetailShowTotal: 'vendorDetails/',

    
    DriverChangeStatus: 'changeDriverStatus',
    DriverDelete: 'deleteDrivers',
    DriverDownloadFiles: 'downloadDriversFile',
    AddUpdateDriver: 'addDrivers',
    GetDriverDetails: 'getDriverDetails/',
    
    DriverGetColumnData: 'agent/driverFieldsListForAgent',
    getDriversList: 'getDriversList',

    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmialColumnData: 'emailTemplatesFieldsList',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    emailTemplatesKeys: 'keysInEmailTemplates',

    GlobalSettingsGetCurrency: 'getCurrency',
    GlobalSettignsGetDateFormat: 'getDateFormat',


    //States
    stateLisitng: 'stateLisitng',
    addStates: 'addStates',
    getStateDetails: 'getStateDetails/',
    deleteStates: 'deleteStates',
    changeStateStatus: 'changeStateStatus',
    stateFieldsList: 'stateFieldsList',
    downloadStateFile: 'downloadStateFile',
    //Brands
    brandList: 'brandListing',
    addBrands: 'addBrands',
    deleteBrands: 'deleteBrands',
    changeBrandStatus: 'changeBrandStatus',
    downloadBrandsFile: 'downloadBrandsFile',
    getBrandDetails: 'getBrandDetails/',
    brandFieldsList: 'brandFieldsList',
    //Orders
    orderListing: 'orderListingForAgent',
    getOrderDetails: 'getOrderDetails/',
    changeOrderStatus: 'changeOrderStatus',
    orderFieldsList: 'orderFieldsList',
    downloadOrderFile: 'downloadOrderFile',
    transactionsLisitngOfOrder: 'transactionsLisitngOfOrder',
    addComments: 'addComments',
    listingOfOrderReturn: 'creditMemosListingOfOrder',
    listingOfOrderShipping: 'shipmentsListingOfOrder',
    listingOfOrderTransaction: 'transactionsListingOfOrder ',
    listingOfOrderComment: 'gettingCommentsOfOrder ',
    createOrderRefund: 'createCreditMemo ',
    transactionsListing: 'transactionsListing',
    shipmentsListing: 'shipmentsListing',
    shipmentsFieldsList: 'shipmentsFieldsList',
    downloadShipmentsFile: 'downloadShipmentsFile',
    returnsListing: 'creditMemosListing',
    returnsFieldsList: 'creditMemoFieldsList',
    downloadCreditMemosFile: 'downloadCreditMemosFile',
    ordersInfoPdf: 'ordersInfoPdf',
    createOrderShipment: 'createShipment',
    getShipmentDetails: 'getShipmentDetails',
    getCreditMemoDetails: 'getCreditMemoDetails',
    changeDriverOfShipment: 'changeDriverOfShipment ',
    confirmOrder: 'confirmOrder ',

     //Master Management
    //country
    CountriesListing : 'countriesListing',
    DeleteCountries : 'deleteCountries',
    DownloadCountriesFile : 'downloadCountriesFile',
    CountriesFieldsList : 'countriesFieldsList',
    ChangeCountriesStatus : 'changeCountriesStatus',
    AddUpdateCountry : 'addUpdateCountry',

    GetCountriesList : 'getCountriesList',

    //TimeZone
    TimezoneListing : 'timezoneListing',
    ChangeTimezoneStatus : 'changeTimezoneStatus',
    DeleteTimezones : 'deleteTimezones',
    DdownloadTimezonesFile : 'downloadTimezonesFile',
    TimezonesFieldsList : 'timezonesFieldsList',
    AddUpdateTimezone : 'addUpdateTimezone',

    //Currency
    CurrencyListing : 'currenciesListing',
    DeleteCurrencies : 'deleteCurrencies',
    DownloadCurrenciesFile : 'downloadCurrenciesFile',
    ChangeCurrenciesStatus : 'changeCurrenciesStatus',
    CurrenciesFieldsList : 'currenciesFieldsList',
    AddUpdateCurrency : 'addUpdateCurrency',

    //Measurements
    addUpdateMeasurement : 'addUpdateMeasurement',
    measurementsListing : 'measurementsListing',
    deleteMeasurements : 'deleteMeasurements',
    downloadMeasurementFile : 'downloadMeasurementFile',
    changeMeasurementsStatus : 'changeMeasurementsStatus',
    measurementsFieldsList : 'measurementsFieldsList',
    getMeasurementsList : 'getMeasurementsList',

    GetCountryDetails : 'getCountryDetails',

    //Global Settings
    GetGlobalSettings : '/agent/getGlobalSettingsForAgent',

    //Ratings
    ReviewListing : 'reviewListing',
    changeReviewStatus: 'changeReviewStatus',
    deleteReviews: 'deleteReviews',
    updateReview: 'updateReview',
    getReviewDetails: 'getReviewDetails/',
    downloadReviewsFile: 'downloadReviewsFile',
    reviewFieldsList: 'reviewFieldsList',

    //Promocode
    PromoCodeListing : 'promoCodeListing',
    addPromoCode : 'addPromoCode',
    deletePromoCode: 'deletePromoCode',
    changePromoCodeStatus: 'changePromoCodeStatus',
    getPromoCodeDetails: 'getPromoCodeDetails/',
    downloadPromoCodeFile: 'downloadPromoCodeFile',
    promoCodeFieldsList: 'promoCodeFieldsList',

    GetTimezoneList: 'getTimezoneList',
    GetCurrenciesList: 'getCurrenciesList',
    AddGlobalSettings: 'addGlobalSettings',

    getNotifications : 'getNotifications',
    getNotificationsCount : 'getNotificationsCount',

    //Payouts
    vendorsPayoutsList : 'getVendorsPayoutsList',
    getCustomerOrderDetails: 'getCustomerOrderDetails',
    downloadPayOutFile: 'downloadPayOutFile',
    payOutsFieldsList: 'payOutsFieldsList',
    orderPayouts: 'orderPayouts',
    ordersFieldsListInPayouts : 'ordersFieldsListInPayouts',
    createPayoutForVendor: 'createPayoutForVendor',
    vendorOrdersPayoutsList: 'vendorOrdersPayoutsList',

    //FAQ
    addUpdateFAQ : 'addUpdateFAQ',
    faqList : 'FAQSListing',
    deleteFAQS : 'deleteFAQS',
    changeFAQSStatus : 'changeFAQSStatus',
    downloadFAQSFile : 'downloadFAQSFile',
    FAQSFieldsList : 'FAQSFieldsList',

    //agent
    Agent_socialAccess:'agent/socialAccess',
    Agent_register:'agent/register',
    Agent_verifyUser :'agent/verifyUser/',
    Agent_login : 'agent/login',
    Agent_forgotPassword :'agent/forgotPassword',
    Agent_resetPassword : 'agent/resetPassword',
    Agent_changePassord :'agent/changePassword',
    Agent_Listing : 'agentListing',
    Agent_getProfile: 'agent/getAgentProfile',
    Agent_editAgentProfile:'agent/editAgentProfile',
    Agent_getDriverDetails :'agent/getDriverDetailsForAgent/',
    Agent_logout:'agent/logOut',
    //Dashboard part
    Agent_getDashboardData:'agent/getDashboardDataForAgent',
    
​    Agent_getStatistics:'agent/getStatisticsForAgent',
//agent​/getStatisticsForAgent
    //earning report
​    Agent_earningReport:'agent/earningReportsForAgent',

    //order-agent
    orderListingForAgent:'orderListingForAgent',
    Agent_getOrderDetails:'agent/getOrderDetailsForAgent',
    orderFilterData:'agent/orderFieldsListForAgent',
    downloadAgentOrders:'agent/downloadOrderFileForAgent',
    //driver Data
    Agent_assignDriver:'agent/assignDriverForAgent',
    DriverList: 'agent/driverListingForAgent',
    Agent_changeDriverStatus:'agent/changeDriverStatusForAgent',
    Agent_downloadDriversFile:'agent/downloadDriversFileForAgent',
    Agent_driverFieldslist :'agent/driverFieldsListForAgent',
    Agent_getDriversList:'agent/getDriversListForAgent',
   orderDetailsForAgent:'agent/getOrderDetailsForAgent',
    //payment Management
    PaymentManagementForAgent:'agent/paymentManagementForAgent',
    Download_driver:'agent/downloadDriversFileForAgent',
   paymentManagementFieldsListForAgent:'agent/paymentManagementFieldsListForAgent',
   downloadPaymentManagementFileForAgent:'agent/downloadPaymentManagementFileForAgent',
   earningReportsFieldsListForAgent:'agent/earningReportsFieldsListForAgent',
   downloadEarningReportsFileForAgent:'agent/downloadEarningReportsFileForAgent',
   changeDriverApprovalStatusForAgent:'agent/changeDriverApprovalStatusForAgent'
    

    // Old Version Api List
    // AdminUserlist: 'adminListing',
    // AdminGetColumnData: 'adminFieldsList',
    // AdminColumnVisibleSettings: 'saveColumnSettings',
    // AdminDeleteUsers: 'deleteAdmins',
    // AdminChangeStatus: 'changeAdminStatus',
    // AdminDownloadforDataTable: 'downloadAdminFile',
    // AdminRolesFieldsList: 'adminRolesFieldsList',
    // AddUpdateAdminUser: 'addAdmin',
    // AdminUserSaveFilter: 'saveFilter',
    // AdminUserDeleteFilter: 'deleteFilter/',
    // AdminUserGetFilter: 'getFilters/',
    // AdminUpdateUser: 'admin/updateUser',
    // GetAdminDetails: 'getSubAdminProfile/',
    // EmailTemplateList: 'listEmail',
    // EmailTemplateDetail: 'detailEmail/',
    // EmialColumnData: 'getEmailColumnValues',
    // EmialColumnVisibleSettings: 'emailColumnSettings',
    // DeleteEmailTemplate: 'deleteTemplate',
    // EmailAddNewEmail: 'addUpdateEmail',
    // EmailChangeStatus: 'changeTemplateStatus',
    // AdminEmailGetEmailTitle: 'getEmailTitle',
    // AdminEmailGetEmailSettings: 'getEmailSettings',
    // AdminEmailAddEmailSettings: 'addEmailSettings',
    // AdminEmailaddDefaultEmailSettings: 'addDefaultEmailSettings',
    // AdminEmailDeleteEmailSettings: 'deleteEmailSetting/',
    // GetSMTPSettings: 'getSmtpDetails',
    // AddSMTPSettings: 'addSmtpSettings',

    // CategoryList: 'categoryListing',
    // CategoryColumnVisibleSettings: 'saveColumnSettings',
    // CategoryChangeStatus: 'changeCategoryStatus',
    // CategoryDelete: 'deleteCategories',
    // CategoryGetFilter: 'getFilters/',
    // CategorySaveFilter: 'saveFilter',
    // CategoryDeleteFilter: 'deleteFilter/',
    // RoleList: 'listRole',
    // AddUpdateRole: 'addRole',
    // GetRoleDetails: 'getRolePermission/',
    // RoleChangeStatus: 'changeRoleStatus',
    // RoleDelete: 'deleteRole',
    // RoleDownload: 'downloadRolesFile',
    // RoleFilter: 'roleFieldsList',
    // ReviewList: 'reviewListing',
    // ReviewColumnData: 'saveColumnSettings',
    // ReviewFilter: 'reviewFieldsList',
    // ReviewChangeStatus: 'changeReviewStatus',
    // ReviewDelete: 'deleteReviews',
    // ReviewDownload: 'downloadReviewsFile',
    // UpdateReview: 'updateReview',
    // GetReviewDetail: 'getReviewDetails/',
    // ReviewGetFilter: 'getFilters/',
    // ReviewSaveFilter: 'saveFilter',
    // ReviewDeleteFilter: 'deleteFilter/',
    // ShippingList: 'shippingListing',
    // ShippingColumnData: 'saveColumnSettings',
    // ShippingFilter: 'shippingFieldsList',
    // ShippingChangeStatus: 'changeShippingStatus',
    // ShippingDelete: 'deleteShippingDetails',
    // ShippingDownload: 'downloadShippingFile',
    // GetShippingDetail: 'getShippingDetails/',
    // addShippingDetails: 'addShippingDetails',
    // ShippingGetFilter: 'getFilters/',
    // ShippingSaveFilter: 'saveFilter',
    // ShippingDeleteFilter: 'deleteFilter/',
    // AddStates: 'addStates',
    // StatesList: 'stateListing',
    // StatesColumnData: 'saveColumnSettings',
    // StatesFilter: 'stateFieldsList',
    // StatesChangeStatus: 'changeStateStatus',
    // StatesDelete: 'deleteStates',
    // StatesDownload: 'downloadStateFile',
    // GetStatesDetails: '/getStateDetails/',
    // statesDropDown: 'statesDropDown',
    // StatesGetFilter: 'getFilters/',
    // StatesSaveFilter: 'saveFilter',
    // StatesDeleteFilter: 'deleteFilter/',
    // BrandsList: 'brandListing',
    // BrandsFilter: 'brandFieldsList',
    // BrandsChangeStatus: 'changeBrandStatus',
    // BrandsDelete: 'deleteBrands',
    // BrandsDownload: 'downloadBrandsFile',
    // AddBrands: 'addBrands',
    // GetBrandsDetails: 'getBrandDetails/',
    // BrandsGetFilter: 'getFilters/',
    // BrandsSaveFilter: 'saveFilter',
    // BrandsDeleteFilter: 'deleteFilter/',
    // SupplierList: 'supplierListing',
    // SupplierFilter: 'supplierFieldsList',
    // SupplierChangeStatus: 'changeSupplierStatus',
    // SupplierDelete: 'deleteSuppliers',
    // SupplierDownload: 'downloadSupplierFile',
    // SupplierColumnData: 'saveColumnSettings',
    // AddSupplier: 'addSuppliers',
    // SupplierGetFilter: 'getFilters/',
    // SupplierSaveFilter: 'saveFilter',
    // SupplierDeleteFilter: 'deleteFilter/',
    // GetSupplierDetails: 'getSupplierDetails/',

    // DiscountList: 'discountListing',
    // DiscountColumnData: 'saveColumnSettings',
    // DiscountChangeStatus: 'changeDiscountStatus',
    // DiscountDelete: 'deleteDiscount',
    // DiscountDownload: 'downloadDiscountFile',
    // AddUpdateDiscount: 'addDiscount',
    // GetDiscountDetails: 'getDiscountDetails/',
    // DiscountGetFilter: 'getFilters/',
    // DiscountSaveFilter: 'saveFilter',
    // DiscountDeleteFilter: 'deleteFilter/',
    // homePageDetails: 'homePageDetails',
    // AddUpdateHome: 'addHomePageDetails',
    // ProductList: 'productListing',
    // ProductFilter: 'productFieldsList',

    // OrderList: 'orderListing',
    // OrderColumnData: 'saveColumnSettings',
    // OrderFilter: "orderFieldsList",
    // ViewOrderDetails: 'getOrderDetails',
    // OrderDownload: 'ordersPdf',
    // InvoiceList: 'invoicesListing',
    // InvoiceColumnData: 'saveColumnSettings',
    // InvoiceFilter: 'invoiceFieldsList',
    // GetInvoicesDetails: 'getInvoiceDetails',
    // InvoiceListOrder: 'invoicesListingOfOrder',
    // ViewInvoiceDetails: 'getInvoiceDetails',
    // InvoiceDownload: 'downloadInvoicePdfFile',
    // ShipmentList: 'shipmentsListing',
    // ShipmentColumnData: 'saveColumnSettings',
    // ShipmentFilter: 'shipmentsFieldsList',
    // ShipmentListOrder: 'shipmentsListingOfOrder',
    // GetShipmentDetails: 'getshipmentsMemoDetails',
    // ViewShipmentDetails: 'getShipmentDetails',
    // ShipmentDownload: 'downloadShipmentsPdfFile',
    // createShipment: 'createShipment',
    // CreditMemosList: 'creditMemosListing',
    // CreditMemosColumnData: 'saveColumnSettings',
    // CreditMemosFilter: 'creditMemoFieldsList',
    // GetCreditMemosDetails: 'getCreditMemoDetails',
    // CreditMemosOrder: 'creditMemosListingOfOrder',
    // ViewCreditMemoDetails: '/getCreditMemoDetails',
    // CreditMemosDownload: 'downloadCreditMemosPdfFile',
    // CreateCreditmemo: 'createCreditMemo',
    // TransactionList: 'transactionsListing',
    // TransactionColumnData: 'saveColumnSettings',
    // TransactionOrder: 'transactionsListingOfOrder',
    // TransactionDownload: 'downloadTransactionsPdfFile',
    // GetComments: 'gettingCommentsOfOrder',
    // SubmitComment: 'addComments',
    // getTransport: 'getTransportInAdmin',
    // OrdersPdf: 'ordersInfoPdf',
    // ChangeOrderStatus: 'changeOrderStatus',
    // GetAdminEmails: 'getAdminEmails',

    // submitEmails: 'addAdminEmails',
    // BestSellers: 'getTopselling',

    // NewUsers: 'getNewUsers',

    // getRevenue: 'getRevenue'
};
