<div class="modal-header">
    <h4 class="modal-title pull-left">{{ type == 'payOutsList' ? 'Generate Payout' : title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #countryForm="ngForm" (ngSubmit)="addCountryInfo(countryForm, commonObject)">

        <div class="form-group" *ngIf="type == 'CurrencyListing' || type == 'TimezoneListing'" [ngClass]="((submitted || countryNameInfo.touched) && countryNameInfo.invalid)?'has-danger':''">
            <ng-select class="ng-select-line" [items]="countryList"  placeholder="Select country"
            [clearable]='false' [searchable]='false' [dropdownPosition]="'auto'" 
            name="Name" #countryNameInfo="ngModel" [(ngModel)]="commonObject.countryId" bindLabel="countryName" bindValue="_id"
              required>
        </ng-select>
            <div class="error-block text-left" *ngIf="((submitted || countryNameInfo.touched) && countryNameInfo.invalid)">
              {{showErrorService.showError('countryNameInfo', countryNameInfo.errors)}}
            </div>
          </div>
        <div class="form-group" *ngIf="type == 'CountriesListing'" [ngClass]="((submitted || countryName.touched) && countryName.invalid)?'has-danger':''">
            <input class="form-control" #countryName="ngModel" [(ngModel)]="commonObject.countryName"  placeholder="Country Name" name="countryName" id="countryName" required />
            <div class="error-block text-left" *ngIf="((submitted || countryName.touched) && countryName.invalid)">
                {{showErrorService.showError('countryName', countryName.errors)}} 
            </div>
        </div>
        <div class="form-group" *ngIf="type == 'CountriesListing'" [ngClass]="((submitted || countryCode.touched) && countryCode.invalid)?'has-danger':''">
            <input class="form-control" #countryCode="ngModel" [(ngModel)]="commonObject.countryCode"  placeholder="Country Code" name="countryCode" id="countryCode" required />
            <div class="error-block text-left" *ngIf="((submitted || countryCode.touched) && countryCode.invalid)">
                {{showErrorService.showError('countryCode', countryCode.errors)}}
            </div>
        </div>

        <div class="form-group" *ngIf="type == 'CountriesListing'" [ngClass]="((submitted || phoneCode.touched) && phoneCode.invalid)?'has-danger':''">
            <input class="form-control" #phoneCode="ngModel" [(ngModel)]="commonObject.phoneCode"  placeholder="Phone Code" name="phoneCode" 
            (keydown)="restrictKeyPressService.AllowPhoneCode($event)" required />
            <div class="error-block text-left" *ngIf="((submitted || phoneCode.touched) && phoneCode.invalid)">
                {{showErrorService.showError('phoneCode', phoneCode.errors)}}
            </div>
        </div>

        <div class="form-group" *ngIf="type == 'TimezoneListing'"  [ngClass]="((submitted || timeZone.touched) && timeZone.invalid)?'has-danger':''">
            <input type="text" class="form-control" placeholder="Enter Time Zone" name="timeZone" #timeZone="ngModel"
              [(ngModel)]="commonObject.timeZone" autocomplete="off" required pattern="^\s*(\w.*)$">
              <div class="error-block text-left" *ngIf="((submitted || timeZone.touched) && timeZone.invalid)">
                {{showErrorService.showError('timeZone', timeZone.errors)}}
            </div>
          </div>

        <div class="form-group" *ngIf="type == 'CurrencyListing'" [ngClass]="((submitted || addCurrency.touched) && addCurrency.invalid)?'has-danger':''">
            <input class="form-control" #addCurrency="ngModel" [(ngModel)]="commonObject.currency"  placeholder="Enter Currency" name="addCurrency"  required />
            <div class="error-block text-left" *ngIf="((submitted|| addCurrency.touched) && addCurrency.invalid)">
                {{showErrorService.showError('addCurrency', addCurrency.errors)}}
            </div>
        </div>

        <div class="form-group" *ngIf="type == 'measurementsList'" [ngClass]="((submitted || measurement.touched) && measurement.invalid)?'has-danger':''">
            <input class="form-control" #measurement="ngModel" [(ngModel)]="commonObject.measurement"  placeholder="Enter Measurement" name="measurement"  required />
            <div class="error-block text-left" *ngIf="((submitted|| measurement.touched) && measurement.invalid)">
                {{showErrorService.showError('measurement', measurement.errors)}}
            </div>
        </div>
        <!--Add/Edit Payouts -->
        <ng-container *ngIf="type === 'payOutsList'">
            <div class="form-group" [ngClass]="((submitted || vendorName.touched) && vendorName.invalid)?'has-danger':''">
                <input class="form-control" #vendorName="ngModel" [(ngModel)]="commonObject.vendorName"  placeholder="Enter Vendor Name" name="vendorName" readonly="readonly" required/>
                <div class="error-block text-left" *ngIf="((submitted|| vendorName.touched) && vendorName.invalid)">
                    {{showErrorService.showError('vendorName', vendorName.errors)}}
                </div>
            </div>
            <div class="form-group" [ngClass]="((submitted || orderIds.touched) && orderIds.invalid)?'has-danger':''">
                <ng-select placeholder="Select Orders" [items]="orderList" name="orderIds" bindLabel="orderId"
                bindValue="_id" [clearable]='false' [searchable]='true' (change)="calculatePayoutAmount($event)" [multiple]="true" [(ngModel)]="commonObject.orderIds" #orderIds="ngModel" (keyup)="filterOrders($event)">
                </ng-select>
            </div>
            <div class="form-group" [ngClass]="((submitted || amount.touched) && amount.invalid)?'has-danger':''">
                <input class="form-control" #amount="ngModel" [(ngModel)]="commonObject.amount"  placeholder="Enter Amount" name="amount" readonly="readonly" required />
                <div class="error-block text-left" *ngIf="((submitted|| amount.touched) && amount.invalid)">
                    {{showErrorService.showError('amount', amount.errors)}}
                </div>
            </div>
        </ng-container>

         <!--Add/Edit FAQ -->
         <ng-container *ngIf="type === 'faqList'">
            <div class="form-group" [ngClass]="((submitted || question.touched) && question.invalid)?'has-danger':''">
                <input class="form-control" #question="ngModel" [(ngModel)]="commonObject.question"  placeholder="Enter Question" name="question" required/>
                <div class="error-block text-left" *ngIf="((submitted|| question.touched) && question.invalid)">
                    {{showErrorService.showError('question', question.errors)}}
                </div>
            </div>
            <div class="form-group" [ngClass]="((submitted || answer.touched) && answer.invalid)?'has-danger':''">
                <textarea rows="5" class="form-control" #answer="ngModel" [(ngModel)]="commonObject.answer"  placeholder="Enter Answer" name="answer" required></textarea>
                <div class="error-block text-left" *ngIf="((submitted|| answer.touched) && answer.invalid)">
                    {{showErrorService.showError('answer', answer.errors)}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type === 'driverAgent' ||type=='agentListing' ||type=='driverListing'">
            <div class="form-group" [ngClass]="((submitted || reason.touched) && reason.invalid)?'has-danger':''">
                <textarea rows="4" class="form-control" #reason="ngModel" [(ngModel)]="commonObject.reason"  placeholder="Enter reason" name="reason" required></textarea>
                <div class="error-block text-left" *ngIf="((submitted|| reason.touched) && reason.invalid)">
                    {{showErrorService.showError('reason', reason.errors)}}
                </div>
            </div>
        </ng-container>
        <div class="text-right">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </form>
  </div>