<!--<app-password [passwordData]='passwordData'></app-password>-->

<div class="authentic-page">
    <div class="back-img">
      <div class="container">
        <div class="admin-back-img">
          <!--<img src="assets/images/admin-background-banner.png" class="img-fluid admin-back-img">-->
      <div class="authentic-wrapper">
        <div class="row">
         
          <div class="col-md-6">
            <div class="login-container">
              <div class="login-section">      
                <div class="text-center"><img src="assets/images/riceday-logo.svg" class="img-fluid"></div>     
                <h4 class="pb-4 pt-4">Forgot Password?</h4>       
                <p>Enter the email used when you joined and we will send you temporary link to reset your password</p>       
                <form class="example-form" #forgotForm="ngForm" (ngSubmit)="onSubmit(forgotForm, user)">
                  <div class='form-group row'>
                    <label htmlFor="password">Email Address</label>
                    <div class="col-md-12"   [ngClass]="((submitted || emailIdForgot.touched) && emailIdForgot.invalid)?'has-danger':''">
                      <input class="form-control" placeholder="Email Address" type="emailId" name="emailId"
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="on" [(ngModel)]="user.emailId"
                      value="" id="emailId" required #emailIdForgot="ngModel" />
                    <em class='fa fa-envelope' aria-hidden='true'></em>
                    <div class="error-block text-left" *ngIf="((submitted || emailIdForgot.touched) && emailIdForgot.invalid)">
                      {{showErrorService.showError('emailIdForgot', emailIdForgot.errors)}}
                    </div>
                    </div>
                  </div>                               
                  <div class="mt-4">
                    <button class='btn btn-block btn-primary' type='submit'> Submit <span><i class="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                  </div>
                  <div class="text-center mt-3"><a href="#" class="back-login">Back to Login</a></div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6 login-logo">
            <img src="assets/images/admin-forgot-password-banner.png" class="header-logo img-fluid" alt="IndiaNic" title="IndiaNic" />
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
    </div>