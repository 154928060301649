<!--<app-password [passwordData]='passwordData'></app-password>-->

<div class="authentic-page">
  <div class="back-img">
    <div class="container">
      <div class="admin-back-img">
        <!--<img src="assets/images/admin-background-banner.png" class="img-fluid admin-back-img">-->
    <div class="authentic-wrapper">
      <div class="row">
       
        <div class="col-md-6">
          <div class="login-container">
            <div class="login-section">  
            <div class="text-center"><img src="assets/images/riceday-logo.svg" class="img-fluid"></div>          
              <h4 class="pb-4 pt-4">Reset Your Password</h4>              
              <form class="example-form" #passwordForm="ngForm" (ngSubmit)="onSubmitPassword(passwordForm, password)"
                content-type="application/x-www-form-urlencoded">
                <div class='form-group row'>
                  <label htmlFor="password">New password:</label>
                  <div class="col-md-12">
                  <input class="form-control" placeholder="Enter a new password" type="password" name="password"
                    autocomplete="on" [(ngModel)]="password.password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" value=""
                    id="password" required #newPassword="ngModel" />
                  <div class="error-block text-left"
                    *ngIf="((submitted || newPassword.touched) && newPassword.invalid)">
                    {{showErrorService.showError('newPassword', newPassword.errors)}}
                  </div>
                  </div>
                </div>
                <div class='form-group row'>
                  <label htmlFor="Password">Confirm Password:</label>
                  <div class="col-md-12">
                    <input class="form-control" placeholder="Confirm your new password" type="password" name="confirmpassword"
                    [(ngModel)]="password.confirmpassword"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"  value=""
                    autocomplete="off" id="confirmpassword" required #confirmPassword="ngModel" />
                  <div class="error-block text-left"
                    *ngIf="((submitted || confirmPassword.touched) && confirmPassword.invalid)">
                    {{showErrorService.showError('confirmPassword', confirmPassword.errors)}}
                  </div>
                  </div>
                </div>                
                <div class="mt-4">
                  <button class='btn btn-block btn-primary' type='submit'> Reset my password <span><i class="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                </div>
                
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 login-logo reset-password">
          <img src="assets/images/Bitmap.png" class="header-logo img-fluid" alt="IndiaNic" title="IndiaNic" />
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>