import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// plugins
import swal from 'sweetalert2';

// environment
import { environment } from 'src/environments/environment';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
import { DEFAULT_LANGUAGE } from '../../common/constants/categoryType-constants';

// service
import { BroadcasterService } from '../../common/service/services/broadcaster.service';
import { CommonService } from '../../common/service/services/common.service';
import { LocalStorageService } from '../../common/service/services/local-storage.service';
import { ShowErrorService } from '../../common/service/services/show-error.service';
import { RestrictKeyPressService } from '../../common/service/services/restrict-key-press.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: [
  ]
})
export class RegisterComponent implements OnInit {

  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
  public uploadImageUrl = environment.uploadImgUrl;
  public user: any = {};
  agent: boolean = true;
  gst_details = false;
  bank_info = false;
  set_pwd = false;
  password = false;
  agentDetails: any = {};

  showPassword = false;
  showConfirmPassword = false;
  paramsData: any;
  public errorMsg:string = "";
  constructor(private localStorageService: LocalStorageService, 
    private commonService: CommonService,
    private broadcasterService: BroadcasterService,
     public showErrorService: ShowErrorService,public restrictKeyPressService:RestrictKeyPressService, private activatedRoute: ActivatedRoute,
     private router: Router) {
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.client.fullname = queryParams.fullname;
        // this.client.last_name = queryParams.last_name;
        this.client.email = queryParams.email;
      });
  }

  ngOnInit() {
    if (this.localStorageService.getToken('Id') != null && this.localStorageService.getToken('Pass') != null) {
          }
  }
  /*************************************************************
  @Purpose     : Register Client
  @Parameter   : form, client
  @Return      : NA
  /*************************************************************/
  client: any = {};
  submitted  = false;
  showErr = false;
  showErrPassword =false
  registerClient(form,client) {
    let req = {
      "fullName": client.fullname,
      "emailId": client.email,
      "mobileNo": client.mobileNo,
      "countryCode": "+91",
      "password": client.password,
      "deviceToken": "",
      "device": "",
    }
    this.submitted = true;
    if (form.valid) {
      this.showErrPassword = false;
      this.commonService.callApi(this.callAPIConstants.Agent_register,req, 'post', true, false, false)
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    } else {
      this.showErrPassword = true;
    }

  }

}
