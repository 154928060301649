export const messages = {
    ERROR: {
        REQUIRED: {
            Email: 'Enter Email Address',
            Password: 'Enter Password',
            password: 'Enter Password',
            Username: 'Enter Username',
            currentPassword: 'Enter Current Password',
            newPassword: 'Enter New Password',
            confirmPassword: 'Enter Confirm Password',
            firstName: 'Enter First Name',
            lastName: 'Enter Last Name',
            mobileNo: 'Enter Mobile Number',
            emailId: 'Enter Email',
            roleName: 'Enter Role',
            PhoneNo: ' Enter Mobile Number',
            Amount: ' Enter Amount',
            filterName: 'Enter Name of Filter',
            emailKey: 'Enter Title',
            subject: 'Enter Subject',
            fromEmail: 'Enter From E-mail',
            emailTemplateId: 'Select Event',
            host: 'Enter SMTP Host',
            port: 'Enter SMTP Port',
            defaultFromEmail: 'Enter Default From EMail',
            defaultAdminEmail: 'Enter Default Admin Email',
            metaTitle: 'Enter Meta Title',
            metaDescription: 'Enter Meta Description',
            metaKeyword: 'Enter Meta Keyword',
            pageTitle: 'Enter Page Title',
            pageUrl: 'Enter Page URL',
            currency: 'Select Currency',
            dateFormat: 'Select Date Format',
            categoryName: 'Enter Category Name',
            customUrl: 'Enter Custom Url',
            productName: 'Enter Product Name',
            price: 'Enter Price',
            weight: 'Enter Weight',
            discount: 'Enter Discount',
            stockQuantity: 'Enter Stock Qunantity',
            availability: 'Enter Availability',
            supplierId: 'Select Supplier',
            brandId: 'Select Brand',
            selectedCategoryName1: 'Select Category',
            brandName: 'Enter Brand Name',
            driverName: 'Enter Name',
            drivingLicenseNumber: 'Enter Driving License Number',
            province: 'Enter Provience',
            hasc: 'Enter HASC',
            capital: 'Enter capital',
            address: 'Enter Address',
            title: 'Enter Title',
            description: 'Enter Description',
            bannerUrl: 'Enter Banner Url',
            mobile: 'Enter Contact Number',
            comment: 'Enter Comment',
            reason: 'Enter Reason',
            driver : 'Select Driver',
            driverId : 'Select Driver',
            discountType : 'Select Discount Type',
            countryName : 'Enter Country Name',
            timezone: 'Enter Timezone',
            timezoneInfo: 'Select Timezone',
            countryCode: 'Enter Country Code',
            phoneCode:'Enter Phone Code',
            addCurrency: 'Enter Currency ',
            countryNameInfo: 'Select Country',
            promoCode:'Enter Promocode',
            minAmount:'Enter Minimum Amount',
            maxAmount:'Enter Maximum Amount',
            validity:'Select Validity',
            vendorId:'Select Vendor',
            measurements: 'Enter Quantity',
            measurementsType: 'Select measurement',
            accountSid: 'Enter Account SID',
            offlineMessage: 'Enter Offline Message',
            headerSnippet: 'Enter Header Snippet',
            footerSnippet: 'Enter Footer Snippet',
            tax:'Enter Taxation',
            siteName: 'Enter Site Name',
            dateFormatInfo: 'Select Date Format',
            timeFormatInfo:'Select Time Format',
            siteMentor:'Select Date',
            maxRadius: 'Enter radius',
            maxNumber: 'Enter Total Promocode Usage',
            type: 'Select Usage Type',
            position: 'Select Position',
            driverMaxRadius: 'Enter Radius',
            question: 'Enter Question',
            answer: 'Enter Answer',
            deliveryCostPerKm: 'Enter Cost',
            orderIds: 'Select Order Ids',
            vehicle: 'Select Vehicle',
            vehicleNo: 'Enter Vehicle Number',
            SKU: 'Enter SKU',
            fullname:'Enter Full Name'
        },
        PATTERN: {
            Email: 'Enter Valid Email Address',
            Contactno: 'Invalid Contact Number',
            emailId: 'Enter valid Email',
            fromEmail: 'Enter valid Email',
            adminEmail: 'Enter valid Email',
            newPassword: 'Enter atleast 8 characters with one uppercase, one digit and one special character',
            password: 'Enter atleast 8 characters with one uppercase, one digit and one special character',
            confirmPassword: 'Enter Valid Password',
            pageUrl: 'URL must start with http://',
            bannerUrl: 'URL must start with http://',
        },
        MINLENGTH: {
            mobile: 'Mobile Number should be 10 digits',
            mobileNo: 'Mobile Number should be 10 digits',
            PhoneNo: 'Mobile Number should be 10 digits',
            confirmPassword: 'Require atleast 8 characters',
            newPassword: 'Require atleast 8 characters',
            port: 'Minimum 3 didgit required',
        },
        MAXLENGTH: {
            mobileNo: 'Mobile Number should be 10 digits',
            mobile: 'Contact Number should be 10 digits',
        },
        CUSTOM: {
            ConfirmPassword: 'Confirm password does not match!',
        },
    },
};
