import { Component, Input, OnInit } from '@angular/core';

// environment
import { environment } from 'src/environments/environment';
// services
import { CommonService } from '../../../common/service/services/common.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
//modal
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styles: [],
})
export class AssignDriverComponent implements OnInit {
    callAPIConstants = callAPIConstants;
    URLConstants = URLConstants;
    @Input() action;
    @Input() shipmentId;
    @Input() modalRef;

    constructor(public commonService: CommonService, public showErrorService: ShowErrorService,public BsModalRef: BsModalRef, private modalService: BsModalService) {
        this.getDriverList();
    }

    ngOnInit() {}

    /*************************************************************
    @Purpose     : Get all driver for assigning order 
    @Parameter   : NA
    @Return      : null
    /*************************************************************/
    public driverList = [];
    getDriverList() {
      this.commonService.callApi(this.callAPIConstants.getDriversList,'', 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.driverList = success.data;
        }
      });
    }
    /*************************************************************/
  

    /*************************************************************
        @Purpose     : Assign driver on orders
        @Parameter   : NA
        @Return      : null
    /*************************************************************/
    public driverData = { driverId: '', shipmentId: '' };
    public submitted = false;
    public selectedDriver = '';
    assignDriver(form, driverData) {
    this.submitted = true;
        if (form.valid) {
            this.selectedDriver = driverData.driverId;
            //if(this.action === 'change' && this.shipmentId != undefined){
            if(this.shipmentId != undefined){
                const formData = Object.assign({}, driverData);
                formData['shipmentId']     = this.shipmentId;
                this.commonService.callApi(this.callAPIConstants.changeDriverOfShipment, formData, 'post', false, false).then((success) => {
                    if (success.status === 1) {
                      this.modalRef.hide();
                      this.showErrorService.popToast('success', success.message);
                    } else {
                      this.showErrorService.popToast('error', success.message);
                    }
                });
            }
        }
    }
    /*************************************************************/
}
