import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assignFilter'
})
export class AssignFilterPipe implements PipeTransform {

  // transform(items: any[], searchText: string): any[] {
  //   if(!items) return [];
  //   if(!searchText) return items;
  //   searchText = searchText.toLowerCase();
  //   return items.filter( it => {
  //     return it.driverName.toLowerCase().includes(searchText) || it.address.toLowerCase().includes(searchText) || it.interests.toLowerCase().includes(searchText);
  //   });
  //  }
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it.toLocaleLowerCase().includes(searchText);
    });
  }

}