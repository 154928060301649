import { Component, OnInit,ElementRef,ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

// plugins
import swal from 'sweetalert2';

// environment
import { environment } from 'src/environments/environment';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
import { DEFAULT_LANGUAGE } from '../../common/constants/categoryType-constants';

// service
import { BroadcasterService } from '../../common/service/services/broadcaster.service';
import { CommonService } from '../../common/service/services/common.service';
import { LocalStorageService } from '../../common/service/services/local-storage.service';
import { ShowErrorService } from '../../common/service/services/show-error.service';

import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-login-agent',
  templateUrl: './login-agent.component.html',
  styles: [
  ]
})
export class LoginAgentComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
  public uploadImageUrl = environment.uploadImgUrl;
  public user: any = {};
  public submitted = false;
  auth2: any;
  @ViewChild('loginRef', {static: true }) loginElement: ElementRef;
 
  
  constructor(
    private localStorageService: LocalStorageService, 
    private commonService: CommonService,
    private authService:SocialAuthService,
    private broadcasterService: BroadcasterService, 
    public showErrorService: ShowErrorService, 
    private router: Router) {}


  ngOnInit() {
    if (this.localStorageService.getToken('Id') != null && this.localStorageService.getToken('Pass') != null) {
      this.user = {
        emailId: this.localStorageService.getToken('Id'),
        password: this.commonService.decrypt(this.localStorageService.getToken('Pass')),
        rememberMe: true,
      };
    }
    
  }

  signUp() {
    this.router.navigate(['/update-info'])
  }
   /*************************************************************
  @Purpose     : On facebook
  @Parameter   : form, user
  @Return      : NA
  /*************************************************************/
  signInWithFb(){
    let provider = FacebookLoginProvider.PROVIDER_ID;
    this.authService.signIn(provider).then((data) => {
        let userData = {
          socialId: data.id,
          socialKey: 'fbId',
          fullName: data.name,
          emailId: data.email,
          countryCode: null,
          mobileNo: null,
          deviceToken: null,
          device: null,
        };
        this.socialLogin(userData);
      }
    );
  }

  signInWithGoogle(){
    let provider = GoogleLoginProvider.PROVIDER_ID;
    this.authService.signIn(provider).then((data) => {
        let userData = {
          socialId: data.id,
          socialKey: 'googleId',
          fullName: data.name,
          emailId: data.email,
          countryCode: null,
          mobileNo: null,
          deviceToken: null,
          device: null,
        };
        
        this.socialLogin(userData);
      }
    );
  }

  private socialLogin(userData) {
 
    this.commonService.callApi(this.callAPIConstants.Agent_socialAccess, userData, 'post', true, false).then(success => {
      if (success.status == 1) {
        this.localStorageService.setToken('accessToken', success.access_token);
        this.localStorageService.setToken('language', this.DEFAULT_LANGUAGE);
        //this.localStorageService.setToken('userId', success.data._id);
        //this.localStorageService.setToken('profilePic', success.data.profileImage);
        this.localStorageService.setToken('emailId', success.data.emailId);
        this.localStorageService.setToken('fullname',success.data.fullName);
        
        if (success.data.photo) { this.localStorageService.setToken('photo',
        this.uploadImageUrl + success.data.photo); }
        
        this.getGlobalSettings();
        this.router.navigate([this.URLConstants.DASHBOARD]);

        this.showErrorService.popToast('success', success.message)
      }
      else {
        this.showErrorService.popToast('error', success.message);

      }
    });
  }

  /*************************************************************
  @Purpose     : On Login
  @Parameter   : form, user
  @Return      : NA
  /*************************************************************/
  onLogin(form, user) {
    this.submitted = true;
    if (form.valid) {
      if (user.rememberMe) {
        this.localStorageService.setToken('Id', user.emailId);
        this.localStorageService.setToken('Pass', this.commonService.encrypt(user.password));
      } else {
        this.localStorageService.removeToken('Id');
        this.localStorageService.removeToken('Pass');
      }
      let req = { 
        emailId: user.emailId, 
        password: user.password,
        deviceToken: null,
        device: null,
       };
      this.commonService.callApi(this.callAPIConstants.Agent_login, req, 'post', true, false, false).then((success) => {
        if (success.status === 1) {
          // this.broadcasterService.broadcast('role_permission');
          this.localStorageService.setToken('accessToken', success.access_token);
          this.localStorageService.setToken('language', this.DEFAULT_LANGUAGE);
          this.localStorageService.setToken('fullname', success.data.fullName);
          if (success.data.photo) { this.localStorageService.setToken('photo',
           this.uploadImageUrl + success.data.photo); }
          // this.localStorageService.setToken('role', success.data.role.role);
         
          // this.localStorageService.setToken('role_permission', this.commonService.encrypt(success.data.rolePermission));
          // if(success.data.role.role == 'agent'){this.localStorageService.setToken('Id', this.commonService.encrypt(success.data.vendorId));}
          this.getGlobalSettings();
          // this.showErrorService.popToast('success', success.message);
          // this.router.navigate([this.URLConstants.DASHBOARD]);
          this.localStorageService.setToken('socialLogin',false);
          this.localStorageService.setToken('profileCompleted',success.data.profileCompleted);
          this.router.navigate([this.URLConstants.DASHBOARD]);
          
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/

  /*************************************************************
  // @Purpose     : Show Forgot Password
  // @Parameter   : NA
  // @Return      : NA
  /*************************************************************/
  showForgotPassword() {
    swal({
      title: 'Forgot Password',
      text: 'Please enter your Registered Email. You will receive mail with reset password link.',
      input: 'email',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn btn-outline-primary',
      preConfirm: (email) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            const data = { emailId: email };
            this.commonService.callApi(this.callAPIConstants.Agent_forgotPassword, data, 'post', true, false).then((success) => {
              (success.status === 1) ? this.showErrorService.popToast('success', success.message) :
                this.showErrorService.popToast('error', success.message);
            });
          }, 500);
        });
      },
      allowOutsideClick: () => !swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        if (result.value.status) {
          swal({
            type: 'success',
            title: 'Link Sent',
            html: result.value.message,
          });
        } else {
          swal({
            type: 'error',
            title: 'Error',
            html: result.value.message,
          });
        }
      }
    },
    );
  }
  /*************************************************************/
  public globalData;
  public responseDate = {
    status:1,
    data:
    {
      siteUnderMaintainence:
      {startDate:"2020-08-21T05:30:00.000Z",
        endDate:"2020-09-14T05:30:00.000Z"
      },
      razorpay:{status:false},
        onlineStatus:true,
        deliveryChargeApplicableAmount:0,
        standardDeliveryCharge:0,
        expressDeliveryCharge:0,
        _id:"5fe9bf0923787e7dc428dd6e",
        allCurrency:[],
        allDateFormat:[],
        currency:"INR",
        dateFormat:"MM/dd/yyy",
        footerSnippet:"sample footer snippet",
        headerSnippet:"sample header snippet",
        offlineMessage:"Offline msg Sample",
        siteFavicon:"resposive-logo1610434647663.jpg",
        siteLogoLarge:"riceday_logo1609960675076.png",
        siteLogoSmall:"resposive-logo1610434621490.png",
        siteName:"Riceday.in","tax":10,
        maxRadius:50000,timeFormat:"24 hour",
        timeZone:"GMT+3",commissionPercentage:5,
        deliveryCostPerKm:5,
        driverMaxRadius:5000000,
        metaTitle:"TestMeta",
        metaTitleArabic:"TestMeta",
        metaKeyword:"",
        metaDescription:"",
        metaKeywordArabic:"",
        metaDescriptionArabic:""}
  }

 
  getGlobalSettings() {
    this.commonService.callApi(this.callAPIConstants.GetGlobalSettings, '', 'get', false, false).then((success) => {
      if (success.status === 1) {
        this.globalData = success.data;
        this.localStorageService.setToken('globalSetting', JSON.stringify({
          'siteName': this.globalData.siteName,
          'currency': this.globalData.currency,
          'dateFormat': this.globalData.dateFormat,
          'timeFormat': this.globalData.timeFormat,
          'timeZone': this.globalData.timeZone,
          'siteLogoSmall': this.globalData.siteLogoSmall,
          'siteLogoLarge': this.globalData.siteLogoLarge,
        }));
      }
    });
  }
    /*************************************************************/
  /*************************************************************
  // @Purpose     : Show change Password
  // @Parameter   : NA
  // @Return      : NA
  /*************************************************************/



}
