import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

// service
import { LocalStorageService } from '../../common/service/services/local-storage.service';

declare var google: any;

@Directive({
  selector: '[google-place]',
})
export class GooglePlacesDirective implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  //@Input() addressType: any;
  private element: HTMLInputElement;

  constructor(elRef: ElementRef, public localStorageService: LocalStorageService) {
    // elRef will get a reference to the element where
    // the directive is placed
    this.element = elRef.nativeElement;
  }

  componentForm: any = {
    // street_number: 'short_name',
    // route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
  };

  getFormattedAddress(place) {
    // @params: place - Google Autocomplete place object
    // @returns: location_obj - An address object in human readable format
    const location_obj = {};
    // tslint:disable-next-line: forin
    for (const i in place.address_components) {

      const item = place.address_components[i].types[0];

      if (this.componentForm[item]) {
        const val = place.address_components[i][this.componentForm[item]];

        location_obj['formatted_address'] = place.formatted_address;
        location_obj['address'] = place.name;
        if (item == 'locality' || item == 'administrative_area_level_2') {
          location_obj['city'] = val;
        } else if (item == 'administrative_area_level_1') {
          location_obj['state'] = val;
        } else if (item == 'country') {
          location_obj['country'] = val;
        } else if (item == 'postal_code') {
          location_obj['postal_code'] = val;
        }
        location_obj['countryCode'] = place.address_components[i].short_name;
        location_obj['lat'] = place.geometry.location.lat();
        location_obj['lng'] = place.geometry.location.lng();
      }
    }
    return location_obj;
  }

  ngOnInit() {
    // const options =
    //   (this.localStorageService.getToken('countryCode') && (this.addressType == 'profile' || this.addressType == 'esp-myDetail') ? {
    //     componentRestrictions: { country: this.localStorageService.getToken('countryCode') },
    //   } : {});

    //const autocomplete = new google.maps.places.Autocomplete(this.element, options);
    const autocomplete = new google.maps.places.Autocomplete(this.element);
    autocomplete.setTypes(['establishment']);
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.onSelect.emit(this.getFormattedAddress(autocomplete.getPlace()));
    });
  }

}
