export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    RESET_PASS: '/reset-password',
    SET_PASS: '/set-password',

    /***** Authorized Pages *****/
    UPDTAEINFORMATION:'/update-info',
    CHANGE_PASS: '/change-password',
    MY_PROFILE: '/my-profile',
    PROFILES:'/profiles',
    DASHBOARD: '/dashboard',
    earningReports:'/earning-reports',
    USERS: '/users',
    USERS_DETAIL: '/users/view-details/',

    ADMINUSER_LIST: '/admin-users',
    ADMINUSER_DETAIL: '/admin-users/edit-details/',
    ADDADMINUSER: '/admin-users/AddAdminUser',

    HOME: '/home',
    HOME_DETAILS: '/home/details',
    CMS: '/cms',
    CMS_DETAIL: '/cms/edit-details/',
    VIEW_CMS: '/cms/view-details/',
    ADD_NEW_CMS: '/cms/addNewCms',

    ADMIN_EMAIL: '/admin-email',

    EMAIL_TEMPLATE_list: '/email-template',
    EMAIL_TEMPLATE_detail: '/email-template/edit-details/',
    EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',

    SMTP_SETTING: '/smtp-settings',

    SOCIAL_MEDIA: '/socialMedia',

    GENERAL: '/general',
    MEASUREMENT: '/measurements',
    STATE: '/state',
    ADDSTATE: '/state/add-state',
    EDITSTATE: '/state/edit-state/',
    COUNTRY: '/country',
    CITY: '/city',
    BRAND: '/brands',
    BRAND_DETAIL: '/brands/view-details/',
    ADDBRAND: '/brands/add-brand',
    EDITBRAND: '/brands/edit-brand/',

    GLOBAL_SETTINGS: '/global-settings',
    PAYMENT_GATEWAY: '/payment-gateway',
    NOTIFICATION: '/notification-settings',

    VIEW_NOTIFICATIONS: '/notifications',

    MANAGE_ROLES: '/manage-roles',
    ADDNEWROLE: '/manage-roles/addNewRole',
    MANAGE_ROLES_DETAIL: '/manage-roles/edit-details/',

    PRODUCTS: '/products/list',
    PRODUCTS_DETAIL: '/products/edit-product/',
    ADD_PRODUCTS: '/products/add-product',
    VIEW_PRODUCT: '/products/view-product/',

    CATEGORY: '/category',
    CATEGORY_DETAIL: '/category/edit-category/',
    ADD_CATEGORY: '/category/add-category',

    SUB_CATEGORY_URL: '/s-category',
    SUB_CATEGORY: '/s-category/list/',
    SUB_CATEGORY_DETAIL: '/s-category/edit-s-category/',
    ADD_SUB_CATEGORY: '/s-category/add-s-category',

    SUB_SUB_CATEGORY_URL: '/s-s-category',
    SUB_SUB_CATEGORY: '/s-s-category/list/',
    SUB_SUB_CATEGORY_DETAIL: '/s-s-category/edit-s-s-category/',
    ADD_SUB_SUB_CATEGORY: '/s-s-category/add-s-s-category',

    BULKUPLOADS: '/bulk-uploads',

    VENDOR: '/vendors',
    VENDOR_DETAIL: '/vendors/edit-details/',
    ADD_VENDOR: '/vendors/add-vendor',
    VIEW_VENDOR: '/vendors/view-vendor/',

    DRIVER: '/drivers',
    DRIVER_VIEW_DETAILS: '/drivers/view-details/',
    DRIVER_DETAIL: '/drivers/edit-details/',
    ADD_DRIVER: '/drivers/add-driver',

    ORDER: '/orders',
    RETURN: '/returns',
    SHIPMENT: '/shipments',
    TRANSACTION: '/transactions',
    ORDER_DETAIL: '/orders/view-details/',
    ORDER_REFUND: '/orders/return-details/',
    ORDER_SHIPMENT: '/orders/shipment-details/',
    ORDER_SHIPMENT_VIEW: '/shipments/order-shipment-details/',
    ORDER_REFUND_VIEW: '/returns/order-return-details/',
    SHIPMENT_DETAIL: '/shipments/view-details/',
    RETURN_DETAIL: '/returns/view-details/',
    // ORDER_CANCEL: '/orders/view-details/',

    PAYOUTS: '/payouts',
    VIEW_PAYOUTS: '/payouts/view-details/',
    
    RATING: '/ratings',
    EDIT_RATING: '/ratings/edit-rating/',
    
    PROMOCODE: '/manage-promocodes',
    ADD_PROMOCODE: '/manage-promocodes/add-promocode',
    EDIT_PROMOCODE: '/manage-promocodes/edit-promocode/',
    VIEW_PROMOCODE: '/manage-promocodes/view-details/',
    REGESTERED_AGENT: '/register-agent',
    PAYOUTS_AGENT:'/product-management',
    
    FAQ: '/faq',
};
