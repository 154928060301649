import { Component, Input, OnInit, TemplateRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';

// environment
import { environment } from 'src/environments/environment';
// services
import { CommonService } from '../../../common/service/services/common.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
//modal
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/common/service/services/local-storage.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styles: [],
})
export class OrderDetailComponent implements OnInit {
    callAPIConstants = callAPIConstants;
    URLConstants = URLConstants;
    @Input() orderData;
    @Input() redirectFrom;
    @Output() confirmOrderUpdate =  new EventEmitter();

    public orderId: any ={};
    public orderList: any = [];
    public orderDetail: any = {};
    public orderTotals: any = {};
    public driverDetails: any = {};
    public orderStatusDetails: any = {};
    public orderedProducts: any = {};
    public displayData: boolean = true;
    productsArray: any = [];

    lat: number = 52.358;
    lng: number = 4.916;
    globalSettings: any;

    constructor(public commonService: CommonService,public localStorageService:LocalStorageService, public showErrorService: ShowErrorService,public BsModalRef: BsModalRef, private modalService: BsModalService,public changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.orderDetail        = this.orderData.orderDetails;
        this.orderTotals        = this.orderData.orderTotals;
        this.orderedProducts    = this.orderData.orderedProducts;
        this.driverDetails      = this.orderData.driverDetails;
        this.orderStatusDetails = this.orderData.orderStatusDetails;
        this.productsArray      = this.orderedProducts;
        if(this.orderDetail != undefined){
            this.orderId = this.orderDetail._id;
        }
    }

    /*************************************************************
        @Purpose     : Save comment on orders
        @Parameter   : NA
        @Return      : null
    /*************************************************************/
    public admin = { comment: '', emailToCustomer: false };
    public submitted = false;
    saveComment(form, admin) {
    this.submitted = true;
        if (form.valid) {
            const formData = Object.assign({}, admin);
            formData['orderId']     = this.orderId;
            formData['type']        = 'orderPlaced';
            formData['subStatus']   = 'pending';
            formData['id']          = this.orderDetail.orderId;
            this.commonService.callApi(this.callAPIConstants.addComments, formData, 'post', false, false).then((success) => {
                if (success.status === 1) {
                  this.showErrorService.popToast('success', success.message);
                  form.reset();
                  this.submitted=false;
                } else {
                  this.showErrorService.popToast('error', success.message);
                }
            });
        }
    }
    /*************************************************************/

    /***************************************************************
    @Purpose : Open Cancel Order Modal
    /***************************************************************/
    openModal(templateId: TemplateRef<any>) {
        this.BsModalRef = this.modalService.show(templateId, { class: 'modal-dialog-centered' });
    }
    /*************************************************************/

    /*************************************************************
    @Purpose     : Assign/change driver for order
    @Parameter   : NA
    @Return      : null
    /*************************************************************/
    public driverData = { orderId: this.orderId, driver:'' };
    public submittedDriver = false;
    public assignedDriverId = '';
    assignDriverSubmit(form, admin) {
        this.submittedDriver = true;
        if (form.valid) {
          this.assignedDriverId = admin.driver;
        }
    }
    /*************************************************************/

    /*************************************************************
    @Purpose     : Update quantity of products for confirming order
    @Parameter   : NA
    @Return      : null
    /*************************************************************/
    updateProcessQuantity(index, value, type) {
        
        if(type == 'delete'){
            this.showErrorService.confirmpopToast('warning', 'Do you remove this product ?', 'REMOVE').then((result) => {
                if (result.value === true) {
                    this.orderedProducts.splice(index,1);
                    this.productsArray.splice(index,1);
                }
            });
        }else{
            let max_value = parseInt(this.orderedProducts[index]['ordered']);
            max_value = max_value > 0 ? max_value : parseInt(this.orderedProducts[index]['quantity']);
            value  = parseInt(value);
            if (!isNaN(value)) {
                if (value > 0 && max_value >= value) {
                    // let shipmentData = {},
                    // productData = this.orderedProducts[index];
                    // shipmentData['productId'] = productData['productId'];
                    // shipmentData['productName'] = productData['productName'];
                    // shipmentData['SKU'] = productData['SKU'];
                    // shipmentData['price'] = productData['price'];
                    // shipmentData['salePrice'] = productData['salePrice'];
                    // shipmentData['vendorName'] = productData['vendorName'];
                    // shipmentData['vendorId'] = productData['vendorId'];
                    // shipmentData['brandName'] = productData['brandName'];
                    // shipmentData['quantity'] = value;
                    // shipmentData['productThumbnailImage'] = productData['productImage'];
                    // shipmentData['customUrl'] = productData['customUrl'];
                    // let i = this.productsArray.findIndex(e => e.productId === shipmentData['productId']);
                    // if(i >= 0){
                        this.productsArray[index]['quantity'] = value;
                        this.orderedProducts[index]['subTotal'] = value * this.orderedProducts[index]['salePrice'];
                    //}else{this.productsArray.push(shipmentData);}
                }else {
                    //this.inputShipment.splice(index, 1);
                    //this.productsArray.splice(index, 1);
                    this.productsArray[index]['quantity'] = this.orderedProducts[index]['quantity'];
                    this.showErrorService.popToast('error', "Please enter valid quantity");
                }
            }else{
                this.showErrorService.popToast('error', "Please enter valid quantity");
            }
            this.changeDetectorRef.detectChanges();
        }
        
    }
    /*************************************************************/

    /*************************************************************
    @Purpose     : Confirm order to process further
    @Parameter   : NA
    @Return      : null
    /*************************************************************/
    confirmOrder() {
        const processData =  {orderId: this.orderId, products: this.productsArray};
        this.commonService.callApi(this.callAPIConstants.confirmOrder,processData, 'post', false, false).then((success) => {
            if (success.status === 1) {
                this.showErrorService.popToast('success',success.message);
                this.confirmOrderUpdate.emit();
            }else{
                this.showErrorService.popToast('error', success.message);
            }
        });
    }
    /*************************************************************/
    
    /*************************************************************
  @Purpose     : Get Order Details after order confirm
  @Parameter   : NA
  @Return      : orderData
  /*************************************************************/
  getOrderDetails() {
    const formData = { 'orderId': this.orderId, 'type': 'order' };
    this.commonService.callApi(this.callAPIConstants.getOrderDetails, formData, 'post', false, false).then((success) => {
      if (success.status === 1) {
        this.orderData = success.data;
        this.orderDetail = this.orderData.orderDetails;
        this.orderTotals = this.orderData.orderTotals;
        this.orderedProducts = this.orderData.orderedProducts;
      }
    });
  }
  /*************************************************************/
  ngDoCheck() {
    this.globalSettings = JSON.parse(this.localStorageService.getToken('globalSetting'));
  }
}
