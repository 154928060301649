<div class="authentic-page">
  <div class="back-img">
    <div class="container">
      <div class="admin-back-img">
        <!-- <img src="assets/images/admin-background-banner.png" class="img-fluid admin-back-img">-->
        <div class="authentic-wrapper">
          <div class="row">

            <div class="col-md-6">
              <div class="login-container">
                <div class="login-section">
                  <!--<h3 class="pb-2">Sign in to your account</h3>-->
                  <!--<h4>Welcome Back</h4>-->
                  <div class="text-center"><img src="assets/images/riceday-logo.svg" class="img-fluid"></div>
                  <h5 class="background"><span>Login</span></h5>
                  <form class="example-form" #loginForm="ngForm" (ngSubmit)="onLogin(loginForm, user)"
                    content-type="application/x-www-form-urlencoded">
                    <div class='form-group row'>
                      <label htmlFor="Email">Email Address</label>
                      <div class="col-md-12"
                        [ngClass]="((submitted || Email.touched) && Email.invalid)?'has-danger':''">
                        <input class="form-control" placeholder="Email Address" type="Email" name="Email"
                          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="on"
                          [(ngModel)]="user.emailId" value="" id="Email" required #Email="ngModel" />
                        <em class='fa fa-envelope' aria-hidden='true'></em>
                        <div class="error-block text-left" *ngIf="((submitted || Email.touched) && Email.invalid)">
                          {{showErrorService.showError('Email', Email.errors)}}
                        </div>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label htmlFor="Password">Password</label>
                      <div class="col-md-12"
                        [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
                        <input class="form-control" placeholder="Password" type="password" name="password"
                          [(ngModel)]="user.password"  value="" autocomplete="on" id="password" required
                          #password="ngModel" />
                        <em class='fa fa-lock' aria-hidden='true'></em>
                        <div class="error-block text-left"
                          *ngIf="((submitted || password.touched) && password.invalid)">
                          {{showErrorService.showError('password', password.errors)}}
                        </div>
                      </div>
                    </div>
                    <div class='row mt-2'>
                      <div class='col-md-6 col-sm-12'>
                        <div class='checkbox sm-text-left'>
                          <label>
                            <input type="checkbox" name="status" [(ngModel)]="user.rememberMe">
                            <span></span> Remember Password
                          </label>
                        </div>
                      </div>
                      <div class='col-md-6 col-sm-12'>
                        <div class='text-right sm-text-left'>
                          <a class='auth-link text-gray' [routerLink]="URLConstants.FORGOT_PASS">Forgot password? </a>
                        </div>
                      </div>
                    </div>
                    <div class="mt-15">
                      <button class='btn btn-block btn-primary ml-0' type='submit'> Login <span><i
                            class="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                    </div>

                  </form>
                  <div class="connect-with">
                    <div class="row auto-login mt-3">
                      <div class="col-sm-12 col-md-12">
                        <div class="new-user text-center">
                          <span>Don’t have an account? <a href="javascript:void(0)" (click)="signUp()">Signup</a></span>
                        </div>
                      </div>
                    </div>
                    <div class="divider">
                      <div class="divider-text text-uppercase text-muted or-connect text-center">
                        <span>Or Login with</span>
                      </div>
                    </div>
                    <div class="row auto-login mt-3">
                      <div class="col-sm-12 col-md-6">
                        <a href="javascript:void(0)" (click)="signInWithFb()" rel="noopener noreferrer" title="Facebook"
                          class="facebook-item">
                          <img src="assets/images/facebook.png" alt="Facebook"> Facebook</a>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <a href="javascript:void(0)" (click)="signInWithGoogle()" rel="noopener noreferrer"
                          title="Google" class="google-item">
                          <img src="assets/images/google-icon.svg" alt="Google icon"> Google</a>
                      </div>
                      <!-- <div class="col-md-12 mt-4">
                      <a href="javascript:void(0)" rel="noopener noreferrer" target="_blank" title="Apple"
                          class="apple-item">
                          <span class="icon icon-apple"></span>Sign in with Apple</a>
                  </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-logo">
              <img src="assets/images/admin-login.png" class="header-logo img-fluid" alt="IndiaNic" title="IndiaNic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>