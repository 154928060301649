import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../common/service/services/common.service';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { ShowErrorService } from '../../../common/service/services/show-error.service';
import { RestrictKeyPressService } from '../../../common/service/services/restrict-key-press.service';
import { Router } from '@angular/router';
// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { BroadcasterService } from 'src/app/common/service/services/broadcaster.service';


@Component({
  selector: 'app-general-master-modal',
  templateUrl: './general-master-modal.component.html',
  styles: [
  ]
})
export class GeneralMasterModalComponent implements OnInit {
  public callAPIConstants = callAPIConstants;
  title;
  commonObject:any = {};
  public submitted = false;
  public data;
  public type;
  public apiname;
  public countryList;
  public orderList;
  statusParams: any;


  constructor(public router: Router,public modalRef : BsModalRef, public commonService : CommonService,public showErrorService: ShowErrorService, public broadCasterService: BroadcasterService, public restrictKeyPressService: RestrictKeyPressService) {  
  }

  ngOnInit(): void {
    if(this.data){    
      this.commonObject = this.data;
      if(this.type == 'CountriesListing'){
        this.commonObject.countryId = this.data._id;  
      }if(this.type == 'CurrencyListing'){
        if(this.data.countryId){
          this.commonObject.countryId = this.data.countryId._id;
        }
      }if(this.type == 'TimezoneListing'){
        if(this.data.countryId){
          this.commonObject.countryId = this.data.countryId._id;
        }
      }
    }    
  }
  
  //getcountries List API 
  getCountriesDetails(){
  let obj = {searchText: ''}
  this.commonService.callApi(this.callAPIConstants.GetCountriesList, obj, 'post', false, false, false).then((success) => {
    if (success.status === 1) {    
      this.countryList = success.data;
    }
  }); 
  }

  filterOrders(event){
    let obj = event ?  event.target.value : '';
    const filter_data = { filter: { orderId: obj}, orderIds: this.data.orderIds };  
    this.commonService.callApi(this.callAPIConstants.ordersFieldsListInPayouts, filter_data, 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.orderList = success.data;  
        this.commonObject.orderIds = '';  
      }
    }); 
  }

  calculatePayoutAmount(event){
    const obj = {'orderIds': this.commonObject.orderIds};
    this.commonService.callApi(this.callAPIConstants.orderPayouts, obj, 'post', false, false, false).then((success) => {
      if (success.status === 1) {    
        this.commonObject.amount = success.data;
      }
    }); 
  }
  /***************************************************************
    @Purpose : Add/Update Country/Timezone/Currency
  /***************************************************************/
  addCountryInfo(form, commonObject){
    this.submitted = true; 
    let obj;
    let obj1 :any ={};
    if (form.valid) {

      if(this.type == 'CountriesListing'){
        this.apiname = callAPIConstants.AddUpdateCountry
      }
      else if(this.type == 'CurrencyListing'){
        this.apiname = callAPIConstants.AddUpdateCurrency;
        this.commonObject.currencyId = this.data._id;
      }
      else if(this.type == 'TimezoneListing'){
        this.apiname = callAPIConstants.AddUpdateTimezone;
        this.commonObject.timezoneId = this.data._id;
      }
      else if(this.type == 'measurementsList'){
        this.apiname = callAPIConstants.addUpdateMeasurement;
        this.commonObject.measurementId = this.data._id;
      }
      else if(this.type == 'payOutsList'){
        this.apiname = callAPIConstants.createPayoutForVendor;
        obj = { vendorId: this.data._id, amount: this.commonObject.amount, orderIds: this.commonObject.orderIds };
      }
      else if(this.type == 'faqList'){
        this.apiname = callAPIConstants.addUpdateFAQ;
        this.commonObject.faqId = this.data._id;
      }
      else if(this.type == 'faqList'){
        this.apiname = callAPIConstants.addUpdateFAQ;
        this.commonObject.faqId = this.data._id;
      }
      else if(this.type == 'driverAgent'){
        this.apiname = callAPIConstants.changeDriverApprovalStatusForAgent;
        obj1[this.statusParams]=[this.data._id];
        obj1['rejectionReason']=this.commonObject.reason;
        obj1['approvalStatus']='rejected';

      }
      const apiData = (this.type === 'payOutsList' ? obj : obj1);  
      this.commonService.callApi(this.apiname, apiData, 'post', false, false).then((res) => {
        if(res.status == 1){
          this.showErrorService.popToast('success', res.message);
          this.modalRef.hide();
          window.location.reload()
        }
        else{
          this.showErrorService.popToast('error', res.message);
        }
      })
    }
    else{
      this.submitted = true;
    }
  }
   // *************************************************************//
}
