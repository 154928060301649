<div class="authentic-page">
	<div class="back-img">
		<div class="container">
			<div class="authentic-wrapper">
				<div class="row">
					<div class="col-md-6">
						<div class="login-container">
							<div class="login-section">
								<h3>Let’s get started!</h3>
								<h5 class="login-text background"><span>Create your account</span></h5>

								<form #form="ngForm" (ngSubmit)="registerClient(form, client)">
									<div class="form-group row">
										<label for="fullname">Full Name</label>
										<div class="col-md-12"
											[ngClass]="((submitted || fullname.touched) && fullname.invalid)?'has-danger':''">
											<input class="form-control" type="text"
												[ngClass]="((submitted || fullname.touched) && fullname.invalid)?'has-danger':''"
												placeholder="Enter Full Name" (keydown)="restrictKeyPressService.AllowChar($event)"
												name="fullname" #fullname=ngModel [(ngModel)]="client.fullname" autocomplete="off" required />
											<div class="error-block pl-2" *ngIf="((submitted || fullname.touched) && fullname.invalid)">
												{{showErrorService.showError('fullname', fullname.errors)}}
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label for="mobileNo">Phone number</label>
										<div class="col-md-12"
											[ngClass]="((submitted || mobileNo.touched) && mobileNo.invalid)?'has-danger':''">
											<input class="form-control" type="text"
												[ngClass]="((submitted || mobileNo.touched) && mobileNo.invalid)?'has-danger':''"
												(keydown)="restrictKeyPressService.AllowNumbers($event)" placeholder="Enter Phone Number"
												name="mobile" #mobileNo="ngModel" [(ngModel)]="client.mobileNo" minlength="10" maxlength="10"
												autocomplete="off" required />
											<div class="error-block pl-2" *ngIf="((submitted || mobileNo.touched) && mobileNo.invalid)">
												{{showErrorService.showError('mobile', mobileNo.errors)}}
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label for="email">Email Address</label>
										<div class="col-md-12" [ngClass]="((submitted || Email.touched) && Email.invalid)?'has-danger':''">
											<input class="form-control" type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
												placeholder="Enter Email Address"
												[ngClass]="((submitted || Email.touched) && Email.invalid)?'has-danger':''" name="Email"
												#Email="ngModel" [(ngModel)]="client.email" autocomplete="off" required />
											<div class="error-block pl-2" *ngIf="((submitted || Email.touched) && Email.invalid)">
												{{showErrorService.showError('Email', Email.errors)}}
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label for="fullname">Password</label>
										<div class="col-md-12"
											[ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
											<div [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
												<div class="with-icon">
													<input class='form-control' [type]="showPassword ? 'text' : 'password'" name="password"
														placeholder="Password" autocomplete="off" required #password="ngModel"
														[(ngModel)]="client.password"
														pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" />

													<em [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
														(click)="showPassword = !showPassword"></em>
												</div>
												<div class="form-control-feedback error-block text-left"
													*ngIf="((submitted || password.touched) && password.invalid)">
													{{showErrorService.showError('password', password.errors)}}
												</div>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label for="fullname">Confirm Password</label>
										<div class="col-md-12">
											<div class="form-group"
												[ngClass]="((submitted || confirmPassword.touched || confirmPassword.dirty) && client.confirmPassword != client.password) || (submitted || confirmPassword.touched) && confirmPassword.invalid?'has-danger':''">
												<div
													[ngClass]="((submitted || confirmPassword.touched || confirmPassword.dirty) && client.confirmPassword != client.password) || (submitted || confirmPassword.touched) && confirmPassword.invalid?'has-danger':''">
													<div class="with-icon">
														<input class='form-control' minlength="8" maxlength="20"
															[type]="showConfirmPassword ? 'text' : 'password'" name="confirmPassword"
															[(ngModel)]="client.confirmPassword" placeholder="Enter Confirm Password"
															autocomplete="off" required #confirmPassword="ngModel"
															[ngClass]="((submitted || confirmPassword.touched || confirmPassword.dirty) && client.confirmPassword != client.password) || (submitted || confirmPassword.touched) && confirmPassword.invalid?'has-danger':''" />
														<em [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
															(click)="showConfirmPassword = !showConfirmPassword"></em>
													</div>
													<div class="form-control-feedback error-block text-left"
														*ngIf="((submitted || confirmPassword.touched) && confirmPassword.invalid)">
														{{showErrorService.showError('confirmPassword', confirmPassword.errors)}}
													</div>
													<div class="error-block"
														*ngIf="((submitted || confirmPassword.touched || confirmPassword.dirty) && confirmPassword.valid && (client.confirmPassword != client.password))">
														New password & Confirm Password must be same.
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12 new-use">
											<div class="login-button">
												<button type="submit" class="btn btn-block btn-primary btn-lg ml-0">Sign up <span><i
															class="fa fa-arrow-right" aria-hidden="true"></i></span></button>
											</div>
											<div class="text-center common-small-text mt-3"><span>Already have an account? <a href="/">Sign
														in</a></span></div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="col-md-6 login-logo">
						<img src="assets/images/admin-login.png" class="header-logo img-fluid" alt="IndiaNic" title="IndiaNic" />
					</div>
				</div>
			</div>

			<!-- <div class="header-bar d-flex align-items-center justify-content-between">
				<div class="header-brand-inner">
					<a class="brand-logo" href="javascript:;">
						<img src="assets/images/riceday-logo.svg" class="header-logo" alt="Riceday" title="Riceday" /></a>
				</div>
		
				<div class="card h-300">
					<div class="card-body">
						<h3>Let’s get started!</h3>
						<p class="login-text">Create your account</p>
						<form #form="ngForm" (ngSubmit)="registerClient(form, client)">
							<div class="row">
								<div class="col-md-12">
									<label>
										<span>Full Name</span>
										<input type="text" [ngClass]="((submitted || fullname.touched) && fullname.invalid)?'has-danger':''"
											name="fullname" #fullname=ngModel [(ngModel)]="client.fullname" autocomplete="off" required />
									</label>
									<div class="error-block pl-2" *ngIf="((submitted || fullname.touched) && fullname.invalid)">
										{{showErrorService.showError('fullname', fullname.errors)}}
									</div>
								</div>
								<div class="col-md-12">
									<label>
										<span>Phone number</span>
										<input type="text" [ngClass]="((submitted || mobileNo.touched) && mobileNo.invalid)?'has-danger':''"
											name="mobile" #mobileNo="ngModel" [(ngModel)]="client.mobileNo" minlength="10" maxlength="10"
											autocomplete="off" required />
									</label>
									<div class="error-block pl-2" *ngIf="((submitted || mobileNo.touched) && mobileNo.invalid)">
										{{showErrorService.showError('mobile', mobileNo.errors)}}
									</div>
								</div>
								<div class="col-md-12">
									<label>
										<span>Email</span>
										<input type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
											[ngClass]="((submitted || email.touched) && email.invalid)?'has-danger':''" name="emailId"
											#email="ngModel" [(ngModel)]="client.email" autocomplete="off" required />
									</label>
									<div class="error-block pl-2" *ngIf="((submitted || email.touched) && email.invalid)">
										{{showErrorService.showError('email', email.errors)}}
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<div [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
											<div class="with-icon">
												<label for="fullname">Password
		
													<input class='form-control' [type]="showPassword ? 'text' : 'password'" name="password"
														placeholder="Password" autocomplete="off" required #password="ngModel"
														[(ngModel)]="client.password"
														pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" />
		
													<em [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
														(click)="showPassword = !showPassword"></em>
												</label>
											</div>
											<div class="form-control-feedback error-block text-left"
												*ngIf="((submitted || password.touched) && password.invalid)">
												{{showErrorService.showError('password', password.errors)}}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<label for="fullname">Confirmed Password</label>
										<div [ngClass]="((submitted || confirmPassword.touched) && confirmPassword.invalid)?'has-danger':''">
											<div class="with-icon">
												<input class='form-control' [type]="showConfirmPassword ? 'text' : 'password'"
													name="confirmPassword" placeholder="Confirm Password" autocomplete="off" required #confirmPassword
													[(ngModel)]="client.password_confirmation" />
												<em [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
													(click)="showConfirmPassword = !showConfirmPassword"></em>
											</div>
											<div class="form-control-feedback error-block text-left"
												*ngIf="((submitted || confirmPassword.touched) && confirmPassword.invalid)">
												{{showErrorService.showError('confirmPassword', confirmPassword.errors)}}
											</div>
											<div class="form-control-feedback error-block text-left" *ngIf="showErr">
												Password & Confirm password does not match
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="login-button">
										<button type="submit" class="btn btn-block btn-primary btn-lg">Sign
											up</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>