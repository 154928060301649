<div class="authentic-page">
  <div class="authentic-wrapper">
    <div class="row">
      <div class="col-md-6 p-0 login-logo">
        <img src="assets/images/logo.png" class="header-logo" alt="IndiaNic" title="IndiaNic" />
      </div>
      <div class="col-md-6 p-0">
        <div class="login-container">
          <div class="login-section">
            <h3 class="pb-2">Sign in to your account</h3>
            <form class="example-form" #loginForm="ngForm" (ngSubmit)="onLogin(loginForm, user)"
              content-type="application/x-www-form-urlencoded">
              <div class='form-group row'>
                <label htmlFor="Email">Email:</label>
                <div class="col-md-12" [ngClass]="((submitted || emailId.touched) && emailId.invalid)?'has-danger':''">
                  <input class="form-control" placeholder="Email" type="emailId" name="emailId"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="on" [(ngModel)]="user.emailId"
                    value="" id="emailId" required #emailId="ngModel" />
                  <em class='fa fa-user' aria-hidden='true'></em>
                  <div class="error-block text-left" *ngIf="((submitted || emailId.touched) && emailId.invalid)">
                    {{showErrorService.showError('emailId', emailId.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <label htmlFor="Password">Password:</label>
                <div class="col-md-12"
                  [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
                  <input class="form-control" placeholder="Password" type="password" name="password"
                    [(ngModel)]="user.password"  value="" autocomplete="on" id="password" required
                    #password="ngModel" />
                  <em class='fa fa-lock' aria-hidden='true'></em>
                  <div class="error-block text-left" *ngIf="((submitted || password.touched) && password.invalid)">
                    {{showErrorService.showError('password', password.errors)}}
                  </div>
                </div>
              </div>
              <div class='mt-5'>
                <button class='btn btn-block btn-primary ml-0' type='submit'> Login </button>
              </div>
              <div class='row'>
                <div class='col-md-6 col-sm-6'>
                  <div class='checkbox mt-3'>
                    <label>
                      <input type="checkbox" name="status" [(ngModel)]="user.rememberMe">
                      <span></span> Remember Password
                    </label>
                  </div>
                </div>
                <div>
                  <button class="fa fa-google" (click)="signInWithGoogle()">
                    Login with Google
                  </button>
                  <button class="fa fa-facebook" (click)="signInWithFb()">
                    Login with Facebook
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>