<nav class="nav" role="navigation">
  <div class="header-brand">
      <div class="header-brand-inner">
      <a class="brand-logo" [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;">
        <img [src]="globalSettings ? uploadImgUrl+globalSettings?.siteLogoLarge : 'assets/images/riceday-logo.svg'" class="header-logo" alt="{{globalSettings ? globalSettings?.siteName : 'Fresh Mart' }}" title="{{globalSettings ? globalSettings?.siteName : 'Fresh Mart' }}" />
        <img [src]="globalSettings ? uploadImgUrl+globalSettings.siteLogoSmall : 'assets/images/resposive-logo' " class="header-icon" alt="{{globalSettings ?  globalSettings?.siteName :'Fresh Mart' }}" title="{{globalSettings ?  globalSettings?.siteName : 'Fresh Mart' }}" /></a>
      <div class="brand-tools">
      </div>
    </div>
  </div>
  <div class="text-center user-profile">
    <div class="thumb-img">
      <img [src]="photo  ? photo : 'assets/images/NoProfile.png'" alt="no img" />
    </div>
    <span>
      {{firstname}}
    </span>
  </div>
  <ul class="sidebar nav__list" (click)="checkSideNav($event)">
    <!-- DASHBOARD -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;">
        <span class="nav-link-icon fa fa-bar-chart-o"></span>
        <span class="nav-link-text">Dashboard</span>
      </a>
    </li> 
    <!-- PRODUCT CATALOG -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.DRIVER]">
        <span class="nav-link-icon fa fa fa-sitemap"></span>
        <span class="nav-link-text">
          <span [ngClass]="onDriversModule ? 'sub-nav-active' : ''">Drivers management</span></span>
      </a>
    </li>
    
    <!-- ORDERS -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.ORDER]">
        <span class="nav-link-icon fa fa-shopping-cart"></span>
        <span class="nav-link-text">
          <span [ngClass]="onOrderModule ? 'sub-nav-active' : ''">Order Status</span></span>
      </a>
    </li>

    <!-- Review & Ratings -->
    <!-- <li class="nav-item"  routerLinkActive='active'>
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.PAYOUTS_AGENT]">
        <span class="nav-link-icon fa fa-credit-card" aria-hidden="true"></span>
        <span class="nav-link-text" >
          <span [ngClass]="onPayoutModule ? 
          'sub-nav-active' : ''">Payment Management</span>   
          </span>
      </a>
    </li> -->

    <!-- Earning Reports -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.earningReports]">
        <span class="nav-link-icon fa fa-dollar" aria-hidden="true"></span>
        <span class="nav-link-text">
          <span [ngClass]="onReportsModule ? 
          'sub-nav-active' : ''">Earning Reports</span></span>
      </a>
    </li>

    <!-- My profile -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.PROFILES]">
        <span class="nav-link-icon fa fa-users" aria-hidden="true"></span>
        <span class="nav-link-text">
          <span [ngClass]="onMyProfileModule ?
          'sub-nav-active' : ''">My profile</span></span>
      </a>
    </li>
    
    <!-- Settings -->
    <!-- <li class="nav-item parent" [hidden]="!(localStorageService.getToken('role') === adminRoleConstants.superAdmin)">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-cog" aria-hidden="true"></span>
        <span class="nav-link-text">Settings</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active">
          <a href="javascript:;" [routerLink]="[URLConstants.GLOBAL_SETTINGS]"> <span
              [ngClass]="onGlobalSettingsModule ? 'sub-nav-active' : ''"><i class="fa fa-long-arrow-right"
                aria-hidden="true"></i> Global settings</span> </a>
        </li>
        <li routerLinkActive="sub-nav-active">
          <a href="javascript:;" [routerLink]="[URLConstants.NOTIFICATION]">
            <span [ngClass]="onNotificationModule ? 'sub-nav-active' : ''"><i class="fa fa-long-arrow-right"
                aria-hidden="true"></i> Notification</span></a>
        </li>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.SOCIAL_MEDIA]" href="javascript:;">
            <span [ngClass]="onSocialMediaModule ? 'sub-nav-active' : ''"><i class="fa fa-long-arrow-right"
              aria-hidden="true"></i> Social Media</span></a>
        </li>
      </ul>
    </li> -->
  </ul>
</nav>