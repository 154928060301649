<div class="authentic-page email-verify">
    <div class="back-img">
        <div class="container">
            <div class="authentic-content">
                <div class="authentic-right">
                    <div class="authentic-wrapper">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="verify-mail p-4">
                                    <div class="login-section">
                                        <div class="text-center">
                                            <img src="assets/images/riceday-logo.svg" class="header-logo" alt="logo" title="Logo" />
                                            <div class="login-text d-flex align-items-center justify-content-center mt-5">
                                                <span class="fa fa-check" aria-hidden="true"></span>
                                                <p class="ml-2">Your email is verified</p>
                                            </div>
                                        </div>
                                        <!-- <div class="verify-image">
                                            <img src="assets/images/email-verify2.svg" alt="verify" title="Verify" />
                                        </div> -->
                                        <div class="login-button mt-5">
                                            <button type="button" class="btn btn-block btn-primary ml-0"
                                            [routerLink]="[URLConstants.LOGIN]">Login <span><i class="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>